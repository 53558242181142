import router from '@/router'
// eslint-disable-next-line object-curly-newline
import {
	reactive,
	getCurrentInstance,
	watch,
	toRefs,
} from '@vue/composition-api'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
	const today = new Date()
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

const getRandomFromArray = array =>
	array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
	const { route: resolvedRoute } = router.resolve(route)
	return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
	const vm = getCurrentInstance().proxy
	const state = reactive({
		route: vm.$route,
	})

	watch(
		() => vm.$route,
		r => {
			state.route = r
		},
	)

	return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const serializeErrorMessage = (h, error) => {
	// console.log(Object.keys(error.response.data.errors).length)
	if (typeof error.response !== 'undefined') {
		if (typeof error.response.data.errors !== 'undefined') {
			const validationMessages = error.response.data.errors
			if (typeof validationMessages === 'string') {
				return h('span', validationMessages)
			}
			console.log(Object.keys(validationMessages))
			if (Object.keys(validationMessages).length) {
				return h(
					'ul',
					Object.keys(validationMessages).map(element =>
						validationMessages[element].map(el => h('li', el)),
					),
				)
			}
			if (validationMessages.length) {
				return h(
					'ul',
					validationMessages.map(element => h('li', element.message)),
				)
			}
			return h('span', error.response.data)
		}
		if (typeof error.response.data.message !== 'undefined') {
			const validationMessages = error.response.data.message
			if (typeof validationMessages === 'string') {
				return h('span', validationMessages)
			}
			if (Object.keys(validationMessages).length) {
				return h(
					'ul',
					validationMessages.map(element => h('li', element)),
				)
			}
			return h('span', error.response.data)
		}
		if (typeof error.response.data !== 'undefined') {
			return h('span', error.response.data)
		}
	} else if (typeof error === 'string') {
		return h('span', error)
	} else {
		return h('span', 'something wrong, silahkan coba kembali.')
	}
	return h('span', 'something wrong, silahkan coba kembali.')
}
